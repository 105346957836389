module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":["gatsby-remark-autolink-headers",{"resolve":"gatsby-remark-copy-linked-files","options":{"ignoreFileExtensions":[]}},{"resolve":"gatsby-remark-mermaid","options":{"mermaidOptions":{"themeCSS":"\n            .node rect,\n            .node circle {\n              stroke-width: 2px;\n              stroke: #3f20ba;\n              fill: #F4F6F8;\n            }\n            .node.secondary rect,\n            .node.secondary circle,\n            .node.tertiary rect,\n            .node.tertiary circle {\n              fill: white;\n            }\n            .node.secondary rect,\n            .node.secondary circle {\n              stroke: #f25cc1;\n            }\n            .node.tertiary rect,\n            .node.tertiary circle {\n              stroke: #41d9d3;\n            }\n          "}}},"gatsby-remark-prismjs-title",{"resolve":"gatsby-remark-prismjs","options":{"showLineNumbers":true}},"gatsby-remark-rewrite-relative-links",{"resolve":"gatsby-remark-check-links","options":{}}],"remarkPlugins":[[null,{"wrapperComponent":"MultiCodeBlock"}]]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"wgrIo8Bul0Ujl8USETG3DB6hONdy4kTg","trackPage":true},
    },{
      plugin: require('../node_modules/gatsby-theme-apollo-docs/gatsby-browser.js'),
      options: {"plugins":[],"siteName":"Yummy Pank8s","menuTitle":"Yummy Pank8s","segmentApiKey":"wgrIo8Bul0Ujl8USETG3DB6hONdy4kTg","algoliaApiKey":"768e823959d35bbd51e4b2439be13fb7","algoliaIndexName":"pank8sdata","baseUrl":"https://pank8s.dev","twitterHandle":"ciriarte","logoLink":"https://pank8s.dev/","defaultVersion":"default","navConfig":{"Core Concepts":{"url":"/exercises/recap-core-concepts","description":"The Lego blocks everyone talks about."},"Cheatsheet":{"url":"https://linuxacademy.com/site-content/uploads/2019/04/Kubernetes-Cheat-Sheet_07182019.pdf","description":"Command incantations to perform common operations."},"Setting up a local dev cluster":{"url":"https://kind.sigs.k8s.io/docs/user/quick-start/","description":"The quickest way I've found so far to get a cluster up and running."}},"footerNavConfig":{"Docs":{"href":"https://kubernetes.io/","target":"_blank","rel":"noopener noreferrer"},"Contribute":{"href":"https://github.com/ciriarte/pank8s"}},"root":"/opt/build/repo","subtitle":"Pank8s 🥞","description":"Learning Kubernetes","githubRepo":"ciriarte/pank8s","spectrumPath":"/","sidebarCategories":{"null":["index","whats-new"]}},
    }]
